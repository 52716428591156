export enum Type {
    Single,
    Multi
}

export enum Mode {
    List,
    Path
}

export enum FileType {
    "docx" = 1,
    "xlsx" = 2,
    "pptx" = 3,
    "one" = 4,
}

export enum ConflictBehavior {
    Fail = "fail",
    Replace = "replace",
    Rename = "rename"
}