import { Interoperability } from "../Interoperability";
import { IXrmViewColumn } from "../interfaces/IXrmViewColumn";
import { IColumn } from "../interfaces/IColumn";
import { IExportToExcelResponse } from "../interfaces/IExportToExcelResponse";
import { Workbook, TableColumnProperties } from 'exceljs';
import { blobToBase64 } from "./FileAttribute";
export class DataSet {
    private _webApi: Xrm.WebApi;
    private _fetchXmlParsed?: Document;
    private _entityMetadata: { [key: string]: Xrm.Metadata.EntityMetadata; };
    private _entityName?: string | null;
    private _records?: ComponentFramework.WebApi.Entity[];
    private _attributes?: IColumn[];
    private _viewColumns?: IXrmViewColumn[];
    private _viewName?: string;
    /** 
    * Provide the webApi to make webapi requests against an env.
    * @param webApi webApi
    * ```typescript
    * new FetchXmlConverter(window.Xrm.WebApi);
    * ```
    */
    constructor(webApi: Xrm.WebApi | ComponentFramework.WebApi) {
        this._webApi = Interoperability.WebApi.TryGetXrmImplementation(webApi);
        this._entityMetadata = {};
    }
    /** 
    * Fetches data and stores it to dataset for later excel export. 
    * @param fetchXml string - Data retrieval query
    * @param viewColumns IXrmViewColumn - Array of columns to be shown in excel
    * @param viewName string (opt) - View name. If not populated script will popualte excel name and sheet name with entity name from fetchXml
    * @param maxPageSize number(def: 5000) - Specify a positive number that indicates the number of table records to be returned per page.
    * @param maximumPages number(def: 10) - Specify a positive number that indicates the number of pages.
    */
    public async FetchData(fetchXml: string, viewColumns: IXrmViewColumn[], viewName?: string, maxPageSize: number = 5000, maximumPages: number = 10): Promise<void> {
        this._fetchXmlParsed = new DOMParser().parseFromString(fetchXml, "text/xml");
        this._entityName = this._fetchXmlParsed.getElementsByTagName("entity")[0].getAttribute("name");
        // Throw an error if selector is all-attributes or no attributes
        if (this._fetchXmlParsed.getElementsByTagName("entity")[0].getElementsByTagName('all-attributes')[0] || !this._fetchXmlParsed.getElementsByTagName("entity")[0].getElementsByTagName('attribute')[0]) {
            throw Error('There is no attributes selected or selector is all-attributes!');
        }
        if (!this._entityName) {
            throw new Error("Invalid fetchXml, unable to obtain entity name!");
        }

        // Fetch data
        this._records = await this._retrieveData(fetchXml, maxPageSize, maximumPages);
        this._attributes = await this._getEntityAttributes(this._fetchXmlParsed.getElementsByTagName('entity')[0]);
        this._viewColumns = viewColumns;
        this._viewName = viewName;
    }
    /** 
    * Exports Excel file and returns IExportToExcelResponse object. 
    * IMPORTANT: You need to call FetchData method first. 
    * @param triggerBrowserDownload boolean(default: true) - Determains if file will be downloaded as method is triggered
    */
    public async ToExcelFile(triggerBrowserDownload: boolean = true): Promise<IExportToExcelResponse> {
        if (this._records && this._attributes && this._viewColumns) {
            const excelObject = await this._retrieveExcelObject(this._records, this._attributes, this._viewColumns);
            return this._exportExcel(excelObject, triggerBrowserDownload, this._viewName);
        }
        else {
            throw Error('No data fetched. Please call FetchData method first.');
        }
    }
    private async _exportExcel(excelObject: ComponentFramework.WebApi.Entity[], triggerBrowserDownload: boolean, viewName?: string): Promise<IExportToExcelResponse> {
        // eslint-disable-next-line -- any comes from ExcelJs
        const rows: any[][] = [];
        const columns: TableColumnProperties[] = [];
        const metadataRow = excelObject.shift();
        if (!metadataRow) {
            throw new Error("Failed to obtain table metadata!");
        }

        // This mapping is really bad, we are relying on item order and stuff. Instead we should pass it as key>value object, key=logicalname.
        Object.keys(metadataRow).forEach(x => columns.push({ name: x, filterButton: true }));
        for (const record of excelObject) {
            // eslint-disable-next-line -- any comes from ExcelJs
            const row: any[] = [];
            for (const header of Object.keys(metadataRow)) {
                row.push(record[header]);
            }
            rows.push(row);
        }

        if (rows.length === 0) {
            rows.push(Object.keys(metadataRow).map(x => ""));
        }

        const workbook = new Workbook();
        const sheet = workbook.addWorksheet(`${viewName ?? this._entityName}`);
        sheet.addRow(Object.values(metadataRow));
        sheet.addTable({
            name: 'Table1',
            ref: 'A2',
            headerRow: true,
            style: {
                theme: 'TableStyleMedium2',
                showRowStripes: true,
            },
            columns: columns,
            rows: rows,
        });
        sheet.getRow(1).hidden = true;
        sheet.getColumn('A').hidden = true;
        sheet.columns.forEach(column => { column.width = 40; });
        const workBook = await workbook.xlsx.writeBuffer();
        const blob = new Blob([workBook]);
        const base64 = await blobToBase64(blob);
        if (triggerBrowserDownload) {
            Xrm.Navigation.openFile(
                {
                    fileContent: base64,
                    fileName: `${viewName ?? this._entityName} ${new Date().toISOString()}.xlsx`,
                    fileSize: workBook.byteLength,
                    mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                },
                // @ts-ignore - @types/xrm specify incorrect interface for OpenFileOptions - https://docs.microsoft.com/en-us/power-apps/developer/model-driven-apps/clientapi/reference/xrm-navigation/openfile
                {
                    openMode: 2
                }
            );
        }
        return {
            blob: blob
        };
    }
    private async _retrieveData(fetchXml: string, maxPageSize: number = 5000, maximumPages: number = 10): Promise<ComponentFramework.WebApi.Entity[]> {
        const records: ComponentFramework.WebApi.Entity[] = [];
        let pageCount = 0;
        let response: Xrm.RetrieveMultipleResult | null = null;
        if (this._entityName) {
            do {
                let options = `?fetchXml=${fetchXml}`;
                // This is temporary because in Power Apps only fetchXmlPagingCookie is returned, in portal we also generate nextLink
                if(response?.nextLink) {
                    options = response.nextLink.split('?')[1];
                }
                response = await this._webApi.retrieveMultipleRecords(this._entityName, options, maxPageSize);
                pageCount++;
                if (response.entities.length > 0) {
                    records.push(...response.entities);
                }
            }
            while (response.nextLink && pageCount < maximumPages);
        }
        return records;
    }
    private async _getEntityAttributes(fetchXmlEntity: Element): Promise<IColumn[]> {
        const columns: IColumn[] = [];
        const referencingEntity = fetchXmlEntity.getAttribute('name');
        const attributes = fetchXmlEntity.querySelectorAll(":scope > attribute");
        const linkEntities = fetchXmlEntity.querySelectorAll(':scope > link-entity');
        const alias = fetchXmlEntity.getAttribute('alias');

        for (const at of Array.from(attributes)) {
            const attributeName = at.getAttribute('name');
            if (attributeName && referencingEntity) {
                columns.push({ entityName: referencingEntity, attributeName: attributeName, path: alias });
            }
        }
        for (const le of Array.from(linkEntities)) {
            columns.push(...await this._getEntityAttributes(le));
        }

        return columns;
    }
    private _getLocalizedLabel(attribute: IColumn): string {
        const entityMetadata = this._entityMetadata[attribute.entityName];
        //@ts-ignore - missing getByName in @types/xrm
        let columnName: string = entityMetadata.Attributes.getByName(attribute.attributeName)?.DisplayName;
        if (attribute.path) {
            columnName += ` (${entityMetadata.DisplayName})`;
        }
        return columnName;
    }
    private async _retrieveExcelObject(records: ComponentFramework.WebApi.Entity[], attributes: IColumn[], viewColumns: IXrmViewColumn[]): Promise<object[]> {
        const excelObject: ComponentFramework.WebApi.Entity[] = [];
        const attributesToShow = attributes.filter(x => viewColumns.find(y => (y.relatedEntityName !== '' && y.relatedEntityName === x.entityName && y.name.includes(x.attributeName)) || (y.relatedEntityName === '' && y.name.includes(x.attributeName))));
        // Sorting attributes so they are ordered as presented in viewColumns array
        attributesToShow.sort(function (a, b) {
            const aViewCols = viewColumns.find(x => (x.relatedEntityName !== '' && x.relatedEntityName === a.entityName && x.name.includes(a.attributeName)) || (x.relatedEntityName === '' && x.name.includes(a.attributeName)));
            const bViewCols = viewColumns.find(x => (x.relatedEntityName !== '' && x.relatedEntityName === b.entityName && x.name.includes(b.attributeName)) || (x.relatedEntityName === '' && x.name.includes(b.attributeName)));
            if (aViewCols && bViewCols)
                return viewColumns.indexOf(aViewCols) - viewColumns.indexOf(bViewCols);
            return 0;
        });
        if (this._entityName) {
            const metadata = await Xrm.Utility.getEntityMetadata(this._entityName, []);
            attributesToShow.unshift({ entityName: this._entityName, attributeName: metadata.PrimaryIdAttribute, path: '' });

            const transactionCurrencyAttributes: IColumn[] = [];
            const mapRowExtensions: ComponentFramework.WebApi.Entity = {};
            const mapRow: ComponentFramework.WebApi.Entity = {};
            for (const at of attributesToShow) {
                if (!this._entityMetadata[at.entityName]) {
                    const metadataAttributes = attributesToShow.filter(y => y.entityName === at.entityName).map(x => x.attributeName);
                    // We always attempt to get transactioncurrencyid metadata in case we have a money field present on the entity
                    metadataAttributes.push("transactioncurrencyid");
                    this._entityMetadata[at.entityName] = await Xrm.Utility.getEntityMetadata(at.entityName, metadataAttributes);
                }
                //@ts-ignore - missing getByName in @types/xrm
                const attribute: Xrm.Metadata.AttributeMetadata = this._entityMetadata[at.entityName].Attributes.getByName(at.attributeName);
                mapRow[this._getLocalizedLabel(at)] = (at.path ? `${at.path}.` : '') + at.attributeName;
                if (attribute.AttributeType === AttributeTypeCode.Money) {
                    // Insert Transaction Currency column into the resulting table
                    const transactionCurrencyAttribute: IColumn = { attributeName: "transactioncurrencyid", entityName: at.entityName, path: at.path };
                    if (!attributesToShow.find(x => x.entityName === transactionCurrencyAttribute.entityName && x.attributeName === transactionCurrencyAttribute.attributeName)) {
                        mapRowExtensions[this._getLocalizedLabel(transactionCurrencyAttribute)] = (at.path ? `${at.path}.` : '') + "transactioncurrencyid";
                        transactionCurrencyAttributes.push(transactionCurrencyAttribute);
                    }
                }
            }
            // Transaction currency should be added as last
            excelObject[0] = { ...mapRow, ...mapRowExtensions };
            attributesToShow.push(...transactionCurrencyAttributes);

            for (const record of records) {
                const row: ComponentFramework.WebApi.Entity = {};
                for (const at of attributesToShow) {
                    const localizedLabel = this._getLocalizedLabel(at);
                    const value = this._getValue(record, at);
                    row[localizedLabel] = value ?? null;
                }
                excelObject.push(row);
            }
        }
        return excelObject;
    }
    private _getValue(input: ComponentFramework.WebApi.Entity, attribute: IColumn): string | number | Date | undefined {
        //@ts-ignore - missing getByName in @types/xrm
        const metadataAttribute: Xrm.Metadata.AttributeMetadata = this._entityMetadata[attribute.entityName].Attributes.getByName(attribute.attributeName);
        switch (metadataAttribute.AttributeType) {
            case AttributeTypeCode.Status:
            case AttributeTypeCode.State:
            case AttributeTypeCode.Picklist:
            case AttributeTypeCode.Customer:
            case AttributeTypeCode.Lookup:
            case AttributeTypeCode.Owner:
            case AttributeTypeCode.Boolean:
            // This handles case of MultiSelectPickList - we should properly detect it via AttributeTypeName which is not implemented right now
            case AttributeTypeCode.Virtual:
                if (attribute.path) {
                    return input[`${attribute.path}.${attribute.attributeName}`];
                }
                else {
                    let value = input[attribute.attributeName];
                    // Top level attributes (if lookups) can be returned in the underscore notation
                    if(!value) {
                        value = input[`_${attribute.attributeName}_value`];
                    }
                    return value;
                }
            default:
                if (attribute.path) {
                    const value = input[`${attribute.path}.${attribute.attributeName}`];
                    return (metadataAttribute.AttributeType === AttributeTypeCode.DateTime && value) ? new Date(value) : value;
                }
                else {
                    return metadataAttribute.AttributeType === AttributeTypeCode.DateTime ? new Date(input[attribute.attributeName]) : input[attribute.attributeName];
                }
        }

    }
}

// Comes from XrmEnum.AttributeTypeCode
export class AttributeTypeCode {
    static Boolean: number = 0;
    static Customer: number = 1;
    static DateTime: number = 2;
    static Decimal: number = 3;
    static Double: number = 4;
    static Integer: number = 5;
    static Lookup: number = 6;
    static Memo: number = 7;
    static Money: number = 8;
    static Owner: number = 9;
    static PartyList: number = 10;
    static Picklist: number = 11;
    static State: number = 12;
    static Status: number = 13;
    static String: number = 14;
    static Uniqueidentifier: number = 15;
    static CalendarRules: number = 16;
    static Virtual: number = 17;
    static BigInt: number = 18;
    static ManagedProperty: number = 19;
    static EntityName: number = 20;
}